/* eslint-disable @typescript-eslint/no-dynamic-delete */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Modal } from 'antd';
import isEqual from 'lodash.isequal';
import { useForm } from 'antd/lib/form/Form';
import { IBaseParams, IFormValues, IQuestionnaireAnswer } from 'common/models/formBuilder.models';
import { CUSTOM_MEDICATIONS_MAX_COUNT, QUESTIONNAIRE_UPDATE_INTERVAL } from 'common/config';
import { useTimer } from 'common/hooks/useTimer';
import { FormInput } from 'common/components/Form/FormInput';
import { FormSelect } from 'common/components/Form/FormSelect';
import { medicationDoseValidators, medicationTimeTakenValidators, reliefSelectorOptions } from 'common/const/questionnaire.const';
import { ReactComponent as AddIcon } from 'app/assets/images/svg/add-btn.svg';
import { ReactComponent as RemoveIcon } from 'app/assets/images/svg/remove-btn.svg';
import { ReactComponent as Arrow } from 'app/assets/images/svg/arrow.svg';
import { AddMedicationPopup } from 'app/pages/Diary/Medications/AddMedicationPopup';
import { IQuestionnaireElement, IQuestionnaireModel } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
  patientSessionId: string;
  getPrevPatientSessionsModel: (id: string) => void;
}

export const DiaryMedicationsMobile: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit, patientSessionId, getPrevPatientSessionsModel } = props;
  // True on first visit, further - patientAnswer dependent. Reversed because 1st element specifies answer whether medication wasn't taken
  const [medicationsTaken, setMediationsTaken] = useState<boolean>(!questionnaire.elements[0].patientAnswers);
  const [answers, setAnswers] = useState<IFormValues[]>([]);
  const [answersCopy, setAnswersCopy] = useState<IFormValues[]>([]);
  const [timer, timerLimit, flush] = useTimer(QUESTIONNAIRE_UPDATE_INTERVAL);
  const [medicationIdToRemove, setMedicationIdToRemove] = useState<string | null>(null);
  const [removedMedicationId, setRemovedMedicationId] = useState<string | null>(null);
  const [showAddMedicationPopup, setShowAddMedicationPopup] = useState<boolean>(false);
  const [canAddCustomElement, setCanAddCustomElement] = useState<boolean>(true);
  const [formController] = useForm();
  const isFirstAssessment = questionnaire.prevTextId === null;

  const isDoseEqualsZero = useCallback(
    (textId: string) => {
      return formController.getFieldInstance(`${textId}_dose`)?.input.value === '0';
    },

    [answers]
  );

  const generateElementsArray = (newMedication?: IQuestionnaireAnswer) => {
    let resultMedicationsArray: IQuestionnaireAnswer[] = [];
    const checkboxElement = questionnaire.elements[0];
    const values: [string, IFormValues][] = formController.getFieldsValue();
    const previouslyDeletedMedications = questionnaire.elements.filter((medication) => medication.isUserDeleted);
    const fieldsWithErrors = formController
      .getFieldsError()
      .filter((field) => field.errors.length)
      .map((field) => field.name)
      .flat();

    // Add current values to the result array
    Object.entries(values).forEach((entry: [string, IFormValues]) => {
      const [textId, medicationValues] = entry;
      const element = questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === textId);
      const question = element ? element.question : medicationValues[`${textId}_question`];

      // Remove all fields that fail validation from response object
      Object.keys(medicationValues).forEach((key: string) => fieldsWithErrors.includes(key) && delete medicationValues[key]);

      resultMedicationsArray.push({
        questionId: textId,
        answers: medicationValues,
        question,
        ...(element?.isUserDefined && { isUserDefined: element?.isUserDefined }),
        ...((removedMedicationId === textId || element?.isUserDeleted) && { isUserDeleted: true }),
      });
    });

    // Add previously deleted medications to the result array
    previouslyDeletedMedications.forEach((previouslyDeletedMedication) => {
      const { textId, answers, question, isUserDefined, isUserDeleted } = previouslyDeletedMedication;

      resultMedicationsArray.push({
        questionId: textId,
        answers: answers,
        question,
        isUserDefined,
        isUserDeleted,
      });
    });

    // Add new medication in case it is created
    if (newMedication) {
      // Remove previous value for medication with the same name
      resultMedicationsArray = resultMedicationsArray.filter((medication) => medication.questionId !== newMedication.questionId);
      // Save updated value
      resultMedicationsArray.push(newMedication);
    }

    // Add value for the checkbox about taking any medication
    resultMedicationsArray.push({
      questionId: checkboxElement.textId,
      answers: !medicationsTaken,
      question: checkboxElement.question,
    });

    return resultMedicationsArray;
  };

  const toggleMedicationsTaken = () => {
    setMediationsTaken(!medicationsTaken);
  };

  const addMedication = () => {
    setShowAddMedicationPopup(true);
  };

  const saveMedication = ({ question, dose, time, relief }: { [key: string]: string }) => {
    // default id for new medication
    let newMedicationId = `diary_medications_list_${questionnaire.elements.length + 2}`;
    let isUserDefined = true;

    // Check existing medications with the same name in elements to avoid duplicates
    const previouslyDefinedElement = questionnaire.elements.find(
      (element: IQuestionnaireElement) => element.isUserDeleted && element.question === question
    );

    if (previouslyDefinedElement) {
      newMedicationId = previouslyDefinedElement.textId;
      isUserDefined = !!previouslyDefinedElement.isUserDefined;

      formController.setFields([
        { name: [`${newMedicationId}_dose`], value: dose },
        { name: [`${newMedicationId}_time`], value: time },
        { name: [`${newMedicationId}_relief`], value: relief },
      ]);
    }

    const newMedicationAnswerObject: IQuestionnaireAnswer = {
      questionId: newMedicationId,
      question,
      answers: {
        [`${newMedicationId}_dose`]: dose,
        [`${newMedicationId}_time`]: time,
        [`${newMedicationId}_relief`]: relief,
      },
      isUserDefined,
    };

    handleSubmit({ elements: generateElementsArray(newMedicationAnswerObject) }, true);
    setShowAddMedicationPopup(false);
  };

  const submitData = () => {
    handleSubmit({ elements: generateElementsArray() });
  };

  const handleCancelRemoving = () => {
    setMedicationIdToRemove(null);
  };

  const handleRemoveMedication = () => {
    setRemovedMedicationId(medicationIdToRemove);
    setMedicationIdToRemove(null);
    flush();
  };

  const renderMedicationForm = () => {
    if (!medicationsTaken) {
      return null;
    }

    // remove checkbox and filter deleted medications
    const medicationItemsToShow = questionnaire.elements.slice(1).filter((medication) => !medication.isUserDeleted);

    return medicationItemsToShow.map((element: IQuestionnaireElement) => (
      <div className="diary_medications_item" key={element.textId}>
        <Form.Item name={[element.textId, `${element.textId}_question`]} initialValue={element.question}>
          <span className="diary_medications_item__title">{element.question}</span>
        </Form.Item>

        <FormInput
          name={[element.textId, `${element.textId}_dose`]}
          placeholder="Dose (s)"
          type="number"
          validators={medicationDoseValidators}
          // trick to avoid validation if medication was deleted by user
          disabled={element.isUserDeleted}
          initialValue={element.patientAnswers?.[`${element.textId}_dose`]}
        />
        <FormInput
          name={[element.textId, `${element.textId}_time`]}
          placeholder="Time(s) Taken"
          type="number"
          validators={medicationTimeTakenValidators}
          // trick to avoid validation if medication was deleted by user
          disabled={element.isUserDeleted}
          initialValue={element.patientAnswers?.[`${element.textId}_time`]}
        />
        <FormSelect
          name={[element.textId, `${element.textId}_relief`]}
          options={reliefSelectorOptions}
          placeholder="Relief"
          // trick to avoid validation if medication was deleted by user
          disabled={element.isUserDeleted || isDoseEqualsZero(element.textId)}
          initialValue={element.patientAnswers?.[`${element.textId}_relief`]}
        />
        <Button className="diary_medications_item__remove" onClick={() => setMedicationIdToRemove(element.textId)}>
          {
            <div className="mr-8 ml-8">
              <RemoveIcon className="mr-4 " />
              <span>I don&apos;t take it anymore</span>
            </div>
          }
        </Button>
      </div>
    ));
  };

  useEffect(() => {
    setAnswers(formController.getFieldsValue());

    const customElementsCount = questionnaire.elements.reduce((sum: number, element: IQuestionnaireElement) => {
      !element.isUserDeleted && element.isUserDefined && sum++;
      return sum;
    }, 0);

    setCanAddCustomElement(customElementsCount < CUSTOM_MEDICATIONS_MAX_COUNT);
  }, [questionnaire]);

  useEffect(() => {
    if (!showAddMedicationPopup && timer === timerLimit && (!isEqual(answers, answersCopy) || removedMedicationId)) {
      handleSubmit({ elements: generateElementsArray() }, true);
      setAnswersCopy(answers);

      if (removedMedicationId) {
        setRemovedMedicationId(null);
      }
    }
  }, [timer]);

  return (
    <div className="diary_medications">
      <h1 className="layout-mobile__title">Medications</h1>
      <Checkbox
        className="mb-8 diary_medications__checkbox"
        name="medications_was_used"
        onChange={toggleMedicationsTaken}
        defaultChecked={!medicationsTaken}
      >
        Check if you didn&apos;t take
        <br /> any pain medications today
      </Checkbox>
      <Form
        onFinish={submitData}
        onValuesChange={() => setAnswers(formController.getFieldsValue())}
        preserve={false}
        id="form"
        form={formController}
      >
        {renderMedicationForm()}
      </Form>

      {medicationsTaken && canAddCustomElement && (
        <Button className="layout-mobile__btn diary_medications__add_button" type="ghost" onClick={addMedication}>
          <AddIcon />
          Add medication
        </Button>
      )}

      <AddMedicationPopup
        visible={showAddMedicationPopup}
        saveMedication={saveMedication}
        setShowAddMedicationPopup={setShowAddMedicationPopup}
        elements={questionnaire.elements}
      />

      {!isFirstAssessment && (
        <Button
          className="layout-mobile__btn diary_medications__back_button"
          type="ghost"
          onClick={() => getPrevPatientSessionsModel(patientSessionId)}
        >
          <Arrow />
          Back
        </Button>
      )}

      <Button
        className="layout-mobile__btn"
        type="primary"
        htmlType="submit"
        form="form"
        disabled={medicationsTaken && questionnaire.elements.length <= 1}
      >
        Next
      </Button>

      <Modal
        visible={!!medicationIdToRemove}
        cancelText="No"
        onCancel={handleCancelRemoving}
        okText="Yes"
        onOk={handleRemoveMedication}
      >
        <span>
          Are you sure you want to remove this medication from the list? If you are still prescribed this medication but
          didn&apos;t take it today, please select &quot;No&quot; and then enter &quot;0&quot; for Time(s) taken and Dose(s)
        </span>
      </Modal>
    </div>
  );
};
