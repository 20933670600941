import React, { useMemo } from 'react';
import { Button, Modal, Row } from 'antd';
import Form, { Rule, RuleObject } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { StoreValue } from 'antd/lib/form/interface';
import { FormInput } from 'common/components/Form/FormInput';
import { FormSelect } from 'common/components/Form/FormSelect';
import {
  basicRequiredMessage,
  medicationDoseValidators,
  numericFieldsValidators,
  reliefSelectorOptions,
} from 'common/const/questionnaire.const';
import { IQuestionnaireElement } from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  visible: boolean;
  saveMedication: (newMedicationValues: { [key: string]: string }) => void;
  setShowAddMedicationPopup: (state: boolean) => void;
  elements: IQuestionnaireElement[];
}

export const AddMedicationPopup: React.FC<IComponentProps> = (props) => {
  const { visible, saveMedication, setShowAddMedicationPopup, elements } = props;
  const [formController] = useForm();

  const validateUniqueMedicationName = (_: RuleObject, value: StoreValue) => {
    let isFieldValidating = true;

    for (const element of elements) {
      if (!element.isUserDeleted && element.question.toLowerCase() === value.trim().toLowerCase()) {
        isFieldValidating = false;

        break;
      }
    }

    return isFieldValidating ? Promise.resolve() : Promise.reject();
  };

  const uniqueMedicationNameValidator: Rule[] = [
    { required: true, message: basicRequiredMessage },
    { validator: validateUniqueMedicationName, message: 'Medication already exists' },
  ];

  const isDoseEqualsZero = () => {
    return formController.getFieldInstance('add_medication_dose')?.input.value === '0';
  };

  const onSaveMedication = () => {
    const { add_medication_dose, add_medication_question, add_medication_relief, add_medication_time } =
      formController.getFieldsValue();

    saveMedication({
      question: add_medication_question.trim(),
      dose: add_medication_dose,
      time: add_medication_time,
      relief: add_medication_relief,
    });

    formController.resetFields([
      'add_medication_question',
      'add_medication_dose',
      'add_medication_time',
      'add_medication_relief',
    ]);
  };

  const modalFooter = useMemo(
    () => (
      <Row justify="space-between">
        <Button type="ghost" onClick={() => setShowAddMedicationPopup(false)}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" form="add_medication_form">
          Save
        </Button>
      </Row>
    ),
    []
  );

  return (
    <Modal footer={modalFooter} closable={false} visible={visible}>
      <Form id="add_medication_form" form={formController} onFinish={onSaveMedication}>
        <FormInput
          name="add_medication_question"
          placeholder="Medication"
          type="text"
          rules={uniqueMedicationNameValidator}
          preserve={false}
        />
        <FormInput
          name="add_medication_dose"
          placeholder="Dose (s)"
          type="number"
          validators={medicationDoseValidators}
          preserve={false}
        />
        <FormInput
          name="add_medication_time"
          placeholder="Time(s) Taken"
          type="number"
          validators={numericFieldsValidators}
          preserve={false}
        />
        <FormSelect
          name="add_medication_relief"
          options={reliefSelectorOptions}
          placeholder="Relief"
          disabled={isDoseEqualsZero()}
          preserve={false}
        />
      </Form>
    </Modal>
  );
};
